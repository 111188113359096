import React from 'react';
import PageWrapper from '../../../components/Shared/PageWrapper';


const DoNotSellMyInfo = () => {
    return (
        <PageWrapper page="soon">
            <section className="about-area about-area-mid pt-150 pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-12 col-md-11">
                            <div className="about-right-side pt-25 mb-30">
                                <div className="about-title mb-20">
                                    <h5 className="pink-color">Policies</h5>
                                    <h1>Do Not Sell My Info</h1>
                                </div>
                                <div className="about-text">
                                    {/* 
                                        <h3 className='pink-color'></h3>
                                        <p></p>
                                    */}

                                    <h4 className='pink-color'>Opt-Out of Sale:</h4>
                                    <p>If you are a resident of California, you have the right to opt-out of the sale of your personal information. MyHealingJourney does not sell personal information</p><br/>

                                </div>
                                <div className="button-area">
                                    <a href="/about_us" className="primary_btn btn-icon ml-0"><span>+</span>Read About Us</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </PageWrapper>
    );
};

export default DoNotSellMyInfo;