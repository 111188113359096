import React, { useState } from 'react';
import axios from "axios";

// import { Link } from 'react-router-dom';
// import {useQuery} from 'react-query';

const baseURL = "https://mailers.nugitech.com/myhealingjourney/sendmail";


const RequestFormArea = () => {
   // const sendContactInfo = async () => {
	// 	const res = await fetch('https://mailers.nugitech.com/myhealingjourney/sendmail');
	// 	return res.json();
	// };

   const [formData, sendFormData] = useState({
      title: '',
      subject: '',
      name: '',          
      email: '',
      phone: '',
      message: '',
      communication: '',
      language: '',
      treatment: '',
   });

   const handleSubmit = async() => {
      // store the states in the form data
      const contactFormData = new FormData();
      contactFormData.append("title", formData.title)
      contactFormData.append("subject", formData.subject)
      contactFormData.append("name", formData.name)
      contactFormData.append("email", formData.email)
      contactFormData.append("phone", formData.phone)
      contactFormData.append("message", formData.message)
      contactFormData.append("communication", formData.communication)
      contactFormData.append("language", formData.language)
      contactFormData.append("treatment", formData.treatment)

      try {
         // make axios post request
         const response = await axios({
            method: "post",
            url: baseURL,
            data: contactFormData,
            headers: { "Content-Type": "multipart/form-data" },
         });

         console.log(response.status)
      } catch(error) {
         console.log(error)
      }
   }


   const handleChange = (event) => {
      sendFormData({
        ...formData,
        [event.target.name]: event.target.value
      });
   }

   // useEffect(() => {
   //    axios.post(baseURL, {
   //       title: "My Healing Journey Contact",
   //       body: "This is a new email."
   //    }).then((response) => {
   //       sendFormData(response.data);
   //    });
   // }, [])

   return (
      <>
         <section className="contact-form-area gray-bg pt-100 pb-100">
            <div className="container">
               <div className="form-wrapper">
                  <div className="row align-items-center">
                     <div className="col-xl-8 col-lg-8">
                        <div className="section-title mb-55">
                           {/* <p><span></span> General Inquiries</p> */}
                           <h1>Request a Call Back</h1>
                        </div>
                     </div>
                     {/* <div className="col-xl-4 col-lg-3 d-none d-xl-block ">
                        <div className="section-link mb-80 text-end">
                            <button className="primary_btn btn-icon ml-0" type="submits" onClick={() => formState(false)}><span>+</span>Return to Contact Us</button>
                        </div>
                     </div> */}
                  </div>
                  <div className="contact-form">
                     <form id="contact-form" onSubmit={handleSubmit}>
                        <div className="row">
                           <div className="col-lg-6">
                              <div className="form-box user-icon mb-30">
                                 <label className='form-label'>Select Your Title</label>
                                 <select defaultValue={'Mr.'} name="title" className="" value={formData.title} onChange={handleChange}>
                                    <option defaultValue={'Mr.'}>Mr.</option>
                                    <option defaultValue={'Mrs.'}>Mrs.</option>
                                    <option defaultValue={'Miss.'}>Miss.</option>
                                 </select>
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box email-icon mb-30">
                                 <label className='form-label'>Your Full Name</label>
                                 <input type="text" name="fullname" placeholder="" value={formData.name} onChange={handleChange} />
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box email-icon mb-30">
                                 <label className='form-label'>Your Email</label>
                                 <input type="text" name="email" placeholder="" value={formData.email} onChange={handleChange} />
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box email-icon mb-30">
                                 <label className='form-label'>Your Phone Number</label>
                                 <input type="text" name="phone" placeholder="" value={formData.phone} onChange={handleChange} />
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box user-icon mb-30">
                                 <label className='form-label'>Preferred Mode of Communication</label>
                                 <select defaultValue={'WhatsApp'} name="mode" className="" value={formData.communication} onChange={handleChange}>
                                    <option defaultValue={'WhatsApp'}>WhatsApp</option>
                                    <option defaultValue={'Skype'}>Skype</option>
                                    <option defaultValue={'Phone Call'}>Phone Call</option>
                                 </select>
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box user-icon mb-30">
                                 <label className='form-label'>Preferred Language of Communication</label>
                                 <select defaultValue={'English'} name="language" className="" value={formData.language} onChange={handleChange}>
                                    <option defaultValue={'English'}>English</option>
                                    <option defaultValue={'Arabic'}>Arabic</option>
                                </select>
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box user-icon mb-30">
                                 <label className='form-label'>Who is this treatment for?</label>
                                 <select defaultValue={'Myself'} name="treatment" className="" value={formData.treatment} onChange={handleChange}>
                                    <option defaultValue={'Myself'}>Myself</option>
                                    <option defaultValue={'Someone else'}>Someone else</option>
                                 </select>
                              </div>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-box subject-icon mb-30">
                                 <label className='form-label'>Your Inquiry Subject</label>
                                 <input type="text" name="subject" placeholder="" value={formData.subject} onChange={handleChange} />
                              </div>
                           </div>
                           <div className="col-lg-12">
                              <div className="form-box message-icon mb-30">
                                 <label className='form-label'>Details of your inquiry</label>
                                 <textarea name="message" id="message" cols="30" rows="10" placeholder="" value={formData.message} onChange={handleChange}></textarea>
                              </div>
                              <div className="contact-btn text-center">
                                 <button className="primary_btn btn-icon ml-0" type="submit"><span>+</span> Send Inquiry</button>
                              </div>
                           </div>
                        </div>
                     </form>
                     <p className="ajax-response text-center"></p>
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default RequestFormArea;