import React from 'react';
import { Link } from 'react-router-dom';

const AyurverdicDetails = () => {
    return (
        <>
            <section className="about-area pt-120 pb-120">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="section-title section-title-m-0 mb-30 pos-rel text-end">
                                <div className="section-icon">
                                    <img className="section-back-icon back-icon-right" src="img/section/section-back-icon.png" alt=""/>
                                </div>
                                <div className="section-text section-text-small pos-rel">
                                    <h5>THERE’S MORE TO HEALING THAN JUST MEDICINE</h5>
                                    <h1>Dive into a world of wellness with MyHealingJourney</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="facalty-text mb-30">
                                <p>Our wellness focus is on Ayurvedic treatments that trace back to ancient wisdom to the calming essence of aromatherapy; every therapy is aimed at harmonizing your body, mind, and soul.</p>
                            </div>
                            <div className="col-xl-6 col-lg-5">
                                <div className="section-button text-end d-block d-lg-block">
                                    <Link to="/contact_us" className="primary_btn btn-icon ml-0"><span>+</span>Contact Our Team</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="service-details-area pt-0 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8">
                            <article className="service-details-box">
                                <div className="service-details-text mb-30">
                                    <p>Ayurveda, an ancient system of medicine founded on the principles of balance and harmony within the body, mind, and spirit. It views each individual as a unique combination of three doshas — Vata, Pitta, and Kapha — reflecting their inherent constitution. Ayurvedic treatments aim to restore this balance through a personalized approach, utilizing natural remedies, dietary adjustments, and lifestyle practices.</p>
                                        
                                    <p>Herbal formulations, derived from plants and minerals, play a pivotal role in Ayurvedic therapies, addressing a range of health concerns. Panchakarma, a cleansing and rejuvenation process, is a hallmark of Ayurveda, designed to eliminate toxins and restore vitality. Ayurvedic practitioners also emphasize the importance of daily routines (Dinacharya) and seasonal practices (Ritucharya) to align with nature’s cycles for optimal well-being.</p>
                                    
                                    <p>By addressing the root causes of imbalances and promoting preventive measures, Ayurvedic treatments offer a comprehensive and holistic approach to health, fostering longevity and sustained vitality.</p>
                                    
                                </div>
                            </article>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                        <div className="service-widget mb-50">
                            <div className="widget-title-box mb-30">
                                <h3 className="widget-title">Other Services</h3>
                            </div>
                            <div className="more-service-list">
                                <ul>
                                    <li>
                                    <Link to="/services/visa_and_travel_assistance">
                                        <div className="more-service-icon"><img src="/img/icons/travel.svg" alt="" /></div>
                                        <div className="more-service-title">Visa & Travel Assistance</div>
                                    </Link>
                                    </li>
                                    <li>
                                    <Link to="/services/hospital_and_clinic_coordination">
                                        <div className="more-service-icon"><img src="/img/icons/clinic.svg" alt="" /></div>
                                        <div className="more-service-title">Hospital & Clinic Coordination</div>
                                    </Link>
                                    </li>
                                    
                                    <li>
                                    <Link to="/services/accommodation_and_transportation">
                                        <div className="more-service-icon"><img src="/img/icons/accomodation.svg" alt="" /></div>
                                        <div className="more-service-title">Accommodation & Transportation</div>
                                    </Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AyurverdicDetails;