import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import PageWrapper from '../../../components/Shared/PageWrapper';



const MTCardiology = () => {
    return (
        <PageWrapper page="main">
            <CommonPageHeader title="Neurology" desc="Your Comfort, Our Priority" subtitle="Medical Treatments" banner="neurology-header_bg"/>
            <section className="about-area pt-130 pb-100">
                <div className="container">
                    <div className="row" style={{alignItems: 'center'}}>
                        <div className="col-xl-6 col-lg-6">
                            <div className="about-right-side h4about-right mb-30">
                                <div className="about-title mb-20">
                                    <h5 className="big-pink-color" style={{fontSize: '35px', textTransform: 'capitalize'}}>Neurology</h5>
                                </div>
                                <div className="about-text">
                                    <p className="theme-color">Neurology focuses on diagnosing and treating diseases related to the nervous system, which includes the brain, spinal cord, muscles, and blood vessels. Important tests in neurology include electroencephalography, lumbar puncture, MRI scans, and Computed Axial Tomography (CT) scans.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-1 col-lg-1"></div>
                        <div className="col-xl-4 col-lg-4 relative">
                            <div className="h4about-thumb pos-rel rel-shift">
                                <img src="/img/medical/neurology-1.jpg" alt="" />
                            </div>
                            
                            <div className="concrete-shadow"></div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </PageWrapper>
    );
};

export default MTCardiology;