import React, { useState } from 'react';
// import ContactFormArea from '../ContactFormArea/ContactFormArea';
import RequestFormArea from '../ContactFormArea/RequestFormArea';

const ContactArea = () => {
    const [requestFormArea, setRequestFormArea] = useState(false)
    return (
        <>
            <section className="contact-area pt-120 pb-90" data-background="assets/img/bg/bg-map.png">
                <div className="container">
                    <div className="row">
                        {/* <div className="col-xl-2 col-lg-2 col-md-2"></div> */}
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                                <i className="fas fa-envelope"></i>
                                <h3>General Inquiries</h3>
                                <a href="mailto:contactus@myhealingjourney.uk?subject=Feedback&body=This%20is%20my%20feedback">
                                contactus@myhealingjourney.uk</a>
                                <p></p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4">
                            <div className="contact text-center mb-30">
                                <i className="fas fa-map-marker-alt"></i>
                                <h3>Our Location</h3>
                                <p>Spaces Acero, 1 Concourse Way, Sheffield, England, S1 2BJ.</p>
                            </div>
                        </div>
                        {/* <div className="col-xl-2 col-lg-2 col-md-2"></div> */}
                        <div className="col-xl-4  col-lg-4 col-md-4 ">
                            <div className="contact text-center mb-30">
                                <i className="fas fa-phone"></i>
                                <h3>Request a Call Back</h3>
                                {/* <p>+44 7859 925568</p> */}
                                <div className="contact-btn text-center" style={{marginTop: '20px'}}>
                                    <button className="primary_btn btn-icon ml-0" type="submits" onClick={() => setRequestFormArea(true)}><span>+</span> Request</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {requestFormArea ? <RequestFormArea formState={setRequestFormArea} /> : <></>}
        </>
    );
};

export default ContactArea;