import React from 'react';
import PageWrapper from '../../../components/Shared/PageWrapper';
import FounderDetails from './founderdetails';

const Founder = () => {
    return (
        <PageWrapper page="soon">
            <FounderDetails/>
        </PageWrapper>
    );
};

export default Founder;