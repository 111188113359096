import React from 'react';
import { Link } from 'react-router-dom';
// import useGlobalContext from '../../hooks/useGlobalContext';

const HomeHeroSingleSlide = ({ bg_className, title, btn, btn_link}) => {
   // const videoPopup = useGlobalContext();
   // const {setIsOpen} = videoPopup
   return (
      <>
         <div className={`single-slider slider-height slider_bg_${bg_className}`}>
            <div className='overlay slider-height d-flex align-items-center'>
               <div className="container">
                  <div className="row">
                     <div className="col-xl-6 col-lg-8 col-md-10">
                        <div className="hero-text">
                           <div className="hero-slider-caption ">
                              {/* <h5>We are here for your care.</h5> */}
                              <h1>{title}</h1>
                              {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
                           </div>
                           <div className="hero-slider-btn">
                              <Link to={btn_link} className="primary_btn btn-icon ml-0"><span>+</span>{btn}</Link>

                              {/* <button onClick={() => setIsOpen(true)}
                                 className="play-btn popup-video"><i className="fas fa-play"></i></button> */}

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
};

export default HomeHeroSingleSlide;