import React from 'react';
import { Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Collapsible from 'react-collapsible';

const Sidebar = ({ show, handleClose }) => {
   const Services = <NavLink to="#">Services</NavLink>
   const Medical = <NavLink to="#">Medical Treatments</NavLink>
   const Wellness = <NavLink to="#">Wellness</NavLink>
   const Partnership = <NavLink to="#">Partnership</NavLink>
   // const Translate = <NavLink to="#">Translate</NavLink>
   
   return (
      <>

         <div >
            <Offcanvas show={show} onHide={handleClose} placement='end' className='side__bar'>
               <Offcanvas.Header closeButton>
                  {/* <Offcanvas.Title>Offcanvas</Offcanvas.Title> */}
               </Offcanvas.Header>
               <Offcanvas.Body>

                  <NavLink to="/">
                     <div class="icon_single">Home</div>
                  </NavLink>
                  
                  <NavLink to="/about_us">
                     <div class="icon_single">About Us</div>
                  </NavLink>

                  <Collapsible trigger={Services} triggerTagName="div"
                     triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                     <ul className="sidebar_sub_menu text-white mt-3">
                        <li><NavLink to="/services/accommodation_and_transportation">Accommodation & Transportation</NavLink></li>
                        <li><NavLink to="/services/hospital_and_clinic_coordination">Hospital & Clinic Coordination</NavLink></li>
                        <li><NavLink to="/services/medical_consultation">Medical Consultation & Planning</NavLink></li>
                        <li><NavLink to="/services/patient_consent_form">Patient Consent Form</NavLink></li>
                        <li><NavLink to="/services/visa_and_travel_assistance">Visa & Travel Assistance</NavLink></li>
                     </ul>
                  </Collapsible>

                  <Collapsible trigger={Medical} triggerTagName="div"
                     triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                     <ul className="sidebar_sub_menu text-white mt-3">
                        <li><NavLink to="/medical_treatments/cardiology">Cardiology</NavLink></li>
                        <li><NavLink to="/medical_treatments/cosmetic_surgery">Cosmetic Surgery</NavLink></li>
                        <li><NavLink to="/medical_treatments/dental">Dental</NavLink></li>
                        <li><NavLink to="/medical_treatments/fertility">Fertility</NavLink></li>
                        <li><NavLink to="/medical_treatments/neurology">Neurology</NavLink></li>
                        <li><NavLink to="/medical_treatments/oncology">Oncology</NavLink></li>
                        <li><NavLink to="/medical_treatments/ophthalmology">Ophthalmology</NavLink></li>
                        <li><NavLink to="/medical_treatments/orthopaedics">Orthopaedics</NavLink></li>
                     </ul>
                  </Collapsible>

                  <Collapsible trigger={Wellness} triggerTagName="div"
                     triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                     <ul className="sidebar_sub_menu text-white mt-3">
                        <li><NavLink to="/wellness/ayurverdic_treatments">Ayurverdic Treatments</NavLink></li>
                        <li><NavLink to="/coming_soon">Wellness Events and News</NavLink></li>
                     </ul>
                  </Collapsible>

                  <Collapsible trigger={Partnership} triggerTagName="div"
                     triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false}>
                     <ul className="sidebar_sub_menu text-white mt-3">
                        <li><NavLink to="/partnership/wellness_ceylon">Wellness Ceylon</NavLink></li>
                     </ul>
                  </Collapsible>

                  <NavLink to="/contact_us">
                     <div class="icon_single">Contact Us</div>
                  </NavLink>

                  <br/>
                  <br/>
                  <br/>

                  {/* <Collapsible trigger={Translate} triggerTagName="div"
                     triggerOpenedClassName="icon_close" triggerClassName="iconAdd" open={false} >
                     <ul className="sidebar_sub_menu text-white mt-3">
                        <li>English</li>
                        <li>Arabic</li>
                     </ul>
                  </Collapsible> */}

                  {/* <div id="google_translate_mobile_element"></div> */}

               </Offcanvas.Body>
            </Offcanvas>
         </div>
      </>
   );
};

export default Sidebar;