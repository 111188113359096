import React from 'react';
import { Link } from 'react-router-dom';

const TravelDetails = () => {
    return (
        <>
            <section className="about-area pt-120 pb-120">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="section-title section-title-m-0 mb-30 pos-rel text-end">
                                <div className="section-icon">
                                    <img className="section-back-icon back-icon-right" src="img/section/section-back-icon.png" alt=""/>
                                </div>
                                <div className="section-text section-text-small pos-rel">
                                    <h5>Visa & Travel Assistance</h5>
                                    <h1>Seamless visa processing and travel guidance</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="facalty-text mb-30">
                                <p>Journeying to a new country for medical care can seem daunting. But with MyHealingJourney, it's a breeze. Benefit from our extensive experience in seamless visa processing and astute travel guidance. Every detail, from visa applications to local travel insights, is managed, ensuring you a hassle-free experience.</p>
                            </div>
                            <div className="col-xl-6 col-lg-5">
                                <div className="section-button text-end d-block d-lg-block">
                                    <Link to="/contact_us" className="primary_btn btn-icon ml-0"><span>+</span>Contact Our Team</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="service-details-area pt-0 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8">
                        <article className="service-details-box">
                            <div className="service-details-text mb-30">
                                <p>Embarking on a medical journey can be daunting, especially when it involves navigating visa processes and travel arrangements. At MyHealingJourney, we've harnessed our wealth of experience to offer you seamless Visa and Travel assistance that is second to none. Our team's expertise ensures that your travel plans are smooth, from visa applications to travel guidance, leaving you free to focus on your health and recovery.</p>
                                <p>With MyHealingJourney as your trusted partner, you can rely on our years of experience in handling the intricacies of international travel. We simplify the visa application process, providing comprehensive guidance and support. Our travel assistance encompasses every aspect, from itinerary planning to local insights, ensuring your journey is stress-free and tailored to your needs. Trust in MyHealingJourney's expertise to provide you with the peace of mind you deserve as you embark on your medical and wellness adventure.</p>
                            </div>
                            </article>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                            <div className="service-widget mb-50">
                                <div className="widget-title-box mb-30">
                                    <h3 className="widget-title">Other Services</h3>
                                </div>
                                <div className="more-service-list">
                                    <ul>
                                        <li>
                                        <Link to="/services/visa_and_travel_assistance">
                                            <div className="more-service-icon"><img src="/img/icons/travel.svg" alt="" /></div>
                                            <div className="more-service-title">Visa & Travel Assistance</div>
                                        </Link>
                                        </li>
                                        <li>
                                        <Link to="/services/hospital_and_clinic_coordination">
                                            <div className="more-service-icon"><img src="/img/icons/clinic.svg" alt="" /></div>
                                            <div className="more-service-title">Hospital & Clinic Coordination</div>
                                        </Link>
                                        </li>
                                        
                                        <li>
                                        <Link to="/services/accommodation_and_transportation">
                                            <div className="more-service-icon"><img src="/img/icons/accomodation.svg" alt="" /></div>
                                            <div className="more-service-title">Accommodation & Transportation</div>
                                        </Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default TravelDetails;