import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import PageWrapper from '../../../components/Shared/PageWrapper';


const MTCardiology = () => {
    return (
        <PageWrapper page="main">
            <CommonPageHeader title="Dental" desc="Your Comfort, Our Priority" subtitle="Medical Treatments" banner="dental-header_bg"/>
            <section className="about-area pt-130 pb-100">
                <div className="container">
                    <div className="row" style={{alignItems: 'center'}}>
                        <div className="col-xl-6 col-lg-6">
                            <div className="about-right-side h4about-right mb-30">
                                <div className="about-title mb-20">
                                    <h5 className="big-pink-color" style={{fontSize: '35px', textTransform: 'capitalize'}}>DENTAL TREATMENTS</h5>
                                </div>
                                <div className="about-text">
                                    <p className="theme-color">Dentists offer a range of treatments tailored to individual oral health needs. From addressing gum disease and cavities to repairing broken or chipped teeth, dental procedures like scaling, dental fillings, root canal treatment (RCT), bonding, veneers, and crowns/on-lays are common solutions for various dental issues.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-1 col-lg-1"></div>
                        <div className="col-xl-4 col-lg-4 relative">
                            <div className="h4about-thumb pos-rel rel-shift">
                                <img src="/img/medical/dental-1.jpg" alt="" />
                            </div>
                            
                            <div className="concrete-shadow"></div>
                        </div>
                        
                    </div>
                </div>
            </section>
        </PageWrapper>
    );
};

export default MTCardiology;