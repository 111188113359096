import React from 'react';
import { motion } from "framer-motion";
import { fadeIn } from "../../utils/motion";


const HomeTwoSingleAbout = ({ index, icon, title, spacing, description }) => {
    return (
        <>
            <motion.div
                variants={fadeIn("", "spring", index * 0.5, 0.75)} 
                className="col-xl-3 col-lg-4 col-md-4"
            >
                <div className={`feature-box m${spacing}`}>
                    <div className="feature-small-icon mb-35">
                        <img src={`/img/icons/steps/${icon}.svg`} alt="" className='w-30'/>
                    </div>
                    <div className="feature-small-content">
                        <h3>{title}</h3>
                        <p>{description}</p>
                    </div>
                </div>
            </motion.div>
        </>
    );
};

export default HomeTwoSingleAbout;