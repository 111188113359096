import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import PageWrapper from '../../../components/Shared/PageWrapper';
import AccommodationDetails from './accommodation-details';


const Accommodation = () => {
    return (
        <PageWrapper page="main">
            <CommonPageHeader title="Accomodation & Transportation" desc="Your Comfort, Our Priority" subtitle="Services"  banner="travel-page_header_bg"/>
            <AccommodationDetails/>
        </PageWrapper>
            
    );
};

export default Accommodation;