import React from 'react';
import { Link } from 'react-router-dom';
// import HomeSingleFooterBlog from '../HomeSingleFooterBlog/HomeSingleFooterBlog';


const Footer = () => {
   const year = new Date().getFullYear()

   return (
      <>
         <footer>
            <div className="footer-top primary-bg footer-map pos-rel pt-120 pb-80">
               <div className="container">
                  <div className="row align-items-start" style={{ position: 'relative', zIndex: '9' }}>
                     <div className="col-xl-6 col-lg-6 col-md-6">
                        <div className="footer-contact-info footer-contact-info-3 mb-40">
                           <div className="footer-logo mb-35">
                              <div className="logo pos-rel logo-space-big flex-foot">
                                 <h3 style={{color: 'white'}}>My Healing Journey</h3>
                                 <p style={{color: '#899dab', fontWeight: '500', lineHeight: '30px', marginTop: '15px'}}>At MyHealingJourney, we're more than just the UK's leading medical tourism facilitator; we're your gateway to global wellness. </p>
                                 {/* <h3 className='footer-text'>Follow Us</h3>
                                 <ul className='flex'>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                 </ul> */}
                                 <Link to="/" className=''><img src="/img/logo/myhealingjourney.png" alt="" style={{ width: '20%' }}/></Link>
                              </div>
                           </div>
                           
                        </div>
                     </div>
                     <div className="col-xl-2 col-lg-6 col-md-6">
                        <div className="footer-widget mb-40">
                           <div className="footer-title">
                              <h3>Links</h3>
                           </div>
                           <div className="footer-menu">
                              <ul>
                                 <li><Link to="/about_us">About Us</Link></li>
                                 <li><Link to="/contact_us">Contact Us</Link></li>
                                 <li><Link to="/terms_and_conditions">Terms & Conditions</Link></li>
                                 <li><Link to="/disclaimer">Disclaimer</Link></li>
                              </ul>
                           </div>
                           <br/><br/>
                           <div className="footer-title">
                              <h3>Reference</h3>
                           </div>
                           <div className="footer-menu">
                              <ul>
                                 <li><Link to="/privacy_policy">Privacy Policy</Link></li>
                                 <li><Link to="/cookie_policy">Cookie Policy</Link></li>
                                 {/* <li><Link to="/do_not_sell_my_info">Do Not Sell My Info</Link></li>
                                 <li><Link to="/manage_cookie_preferences">Manage Cookie Preferences</Link></li> */}
                              </ul>
                           </div>
                        </div>
                     </div>
                     <div className="col-xl-2 col-lg-6 col-md-12">
                        <div className="footer-title">
                           <h3>Address</h3>
                        </div>
                        <div className="footer-menu">
                           <b style={{color: '#899dab', fontWeight: '500', lineHeight: '30px'}}>
                           Spaces Acero, 1 Concourse Way, Sheffield, England, S1 2BJ.
                              <br/><br/>
                              Email: contactus@myhealingjourney.uk
                           </b>
                        </div>
                        {/* <div style={{ width: '100%'}}>
                           <iframe title="Contact" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1007.5873790033089!2d-1.4634595911203394!3d53.380735278161104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487983cf5e3307cb%3A0x80c691fe30614746!2sSpaces%20-%20Sheffield%2C%20Spaces%20Acero!5e0!3m2!1sen!2sng!4v1693638138691!5m2!1sen!2sng" style={{ width: '100%', borderRadius: '20px' }} height={400} allowFullScreen="" loading="lazy"></iframe>
                        </div> */}
                     </div>
                  </div>
               </div>
            </div>
            <div className="footer-bottom pt-25 pb-20">
               <div className="container">
                  <div className="row">
                     <div className="col-xl-12">
                        <div className="footer-copyright footer-copyright-3 text-left">
                           <p>All rights reserved by MedTour UK LTD &copy; {year}</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </footer>
      </>
   );
};

export default Footer;