import React from 'react';
import HomeTwoAbout from '../../HomeTwo/HomeTwoAbout/HomeTwoAbout';
import CommonPageHeaderWell from '../../../components/CommonPageHeader/CommonPageHeaderWell';
import PageWrapper from '../../../components/Shared/PageWrapper';

const WellnessCeylon = () => {
    return (
        <PageWrapper page="main">
            <CommonPageHeaderWell title="Wellness Ceylon" subtitle="Partnership" desc="Customized Treatments" banner="wellness-page_header_bg"/>
            {/* wellnesscey-bg */}
            <HomeTwoAbout/>
        </PageWrapper>
    );
};

export default WellnessCeylon;