import React from 'react';
// import { Link } from 'react-router-dom';

const DoctorsTwoSingleTeam = ({image, name, title, about, link, facebook,twitter,linkedIn}) => {
    return (
        <>
            <div className="col-xl-4 col-lg-4 col-md-6">
                <div className="team-wrapper team-box-2 text-center mb-50">
                    <div className="team-thumb">
                        <img src={`/img/team/${image}`} alt="" className="team-images"/>
                    </div>
                    <div className="team-member-info mt-35 mb-25">
                        {/* <h3><Link to={link}>{name}</Link></h3> */}
                        <h3>{name}</h3>
                        <h6 className="f-500 text-up-case letter-spacing-2 pink-color">{title}</h6>
                        <br/>
                        <p className="f-200 justifying">{about}</p>
                    </div>
                    <div className="team-social-profile mb-15">
                        <p></p>
                        {/* <ul>
                            <li><a href={facebook}><i className="fab fa-facebook-f"></i></a></li>
                            <li><a href={twitter}><i className="fab fa-twitter"></i></a></li>
                            <li><a href={linkedIn}><i className="fab fa-linkedin"></i></a></li>
                        </ul> */}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DoctorsTwoSingleTeam;