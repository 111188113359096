import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Sidebar from '../../components/Shared/Sidebar/Sidebar';
import useGlobalContext from '../../hooks/useGlobalContext';

const NavbarMain = () => {
   const [show, setShow] = useState(false);
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);
   const { stickyMenu } = useGlobalContext();

   // const [inputText, setInputText] = useState('');
   // const [targetLanguage, setTargetLanguage] = useState('en'); // Default: English

   // const handleTranslate = async () => {
   //    if (inputText) {
   //      const translatedText = await translateText(inputText, targetLanguage);
   //      console.log(translatedText)
   //    }
   // };
   return (
      <>
         <header>
            {/* <!-- menu-area --> */}
            <div className={stickyMenu ? "sticky_menu header-menu-area header-padding transparrent-header sticky_navBar_bg" :
               "header-menu-area header-padding transparrent-header"}>
               <div className="container-fluid">
                  <div className="row d-flex align-items-center">
                     <div className="col-xl-2 col-lg-2 col-md-8 col-10">
                        <div className="logo pos-rel logo-space">
                           <Link to="/"><img src="/img/logo/myhealingjourney.png" alt="" /></Link>
                        </div>
                     </div>
                     <div className="col-xl-8 col-lg-8 col-md-1 col-1 tablet-trans" style={{ alignItems: 'center'}}>
                        <div className="header__menu header-menu-white">
                           <nav id="mobile-menu">
                              {/* Home, About us, Services, Meet the team, Wellness news  */}
                              <ul>
                                 <li><NavLink to="/">Home</NavLink></li>
                                 <li><NavLink to="/about_us">About Us</NavLink></li>
                                 {/* <li><NavLink to="/meet_the_team">Meet The Team</NavLink></li> */}
                                 <li className='dropdown'><NavLink to="#">Services &nbsp;<i className="far fa-chevron-down"></i></NavLink>
                                    <ul className="submenu">
                                       <li><NavLink to="/services/accommodation_and_transportation">Accommodation & Transportation</NavLink></li>
                                       <li><NavLink to="/services/hospital_and_clinic_coordination">Hospital & Clinic Coordination</NavLink></li>
                                       <li><NavLink to="/services/medical_consultation">Medical Consultation & Planning</NavLink></li>
                                       <li><NavLink to="/services/patient_consent_form">Patient Consent Form</NavLink></li>
                                       <li><NavLink to="/services/visa_and_travel_assistance">Visa & Travel Assistance</NavLink></li>
                                    </ul>
                                 </li>
                                 <li className='dropdown'><NavLink to="#">Medical Treatments &nbsp;<i className="far fa-chevron-down"></i></NavLink>
                                    <ul className="submenu">
                                       <li><NavLink to="/medical_treatments/cardiology">Cardiology</NavLink></li>
                                       <li><NavLink to="/medical_treatments/cosmetic_surgery">Cosmetic Surgery</NavLink></li>
                                       <li><NavLink to="/medical_treatments/dental">Dental</NavLink></li>
                                       <li><NavLink to="/medical_treatments/fertility">Fertility</NavLink></li>
                                       <li><NavLink to="/medical_treatments/neurology">Neurology</NavLink></li>
                                       <li><NavLink to="/medical_treatments/oncology">Oncology</NavLink></li>
                                       <li><NavLink to="/medical_treatments/ophthalmology">Ophthalmology</NavLink></li>
                                       <li><NavLink to="/medical_treatments/orthopaedics">Orthopaedics</NavLink></li>
                                    </ul>
                                 </li>
                                 <li><NavLink to="#">Wellness &nbsp;<i className="far fa-chevron-down"></i></NavLink>
                                    <ul className="submenu">
                                       <li><NavLink to="/wellness/ayurverdic_treatments">Ayurvedic Treatments</NavLink></li>
                                       <li><NavLink to="/coming_soon">Wellness News & Events</NavLink></li>
                                    </ul>
                                 </li>
                                 <li><NavLink to="#">Partnership &nbsp;<i className="far fa-chevron-down"></i></NavLink>
                                    <ul className="submenu">
                                       <li><NavLink to="/partnership/wellness_ceylon">Wellness Ceylon</NavLink></li>
                                    </ul>
                                 </li>
                                 <li id='contact-link-md'><NavLink to="/contact_us">Contact Us</NavLink></li>
                                 {/* <li id='contact-link-lg'>
                                    <div className="header-right f-right contact_btn" style={{ height: '38px'}}>
                                       <NavLink to="/contact_us" className="primary_btn btn-icon-two btn-icon-green"><span>+</span>contact us</NavLink>
                                    </div>
                                 </li> */}
                                 
                              </ul>
                           </nav>
                           
                        </div>
                        <div onClick={handleShow} className="side-menu-icon d-xl-none text-end">
                           <button className="side-toggle border-0 bg-transparent">
                              <i className="fas fa-bars text-white"></i> </button>
                        </div>
                     </div>
                     <div className="col-xl-2 col-lg-2 col-md-1 d-flex d-lg-flex d-xl-flex col-1 mobile-trans" style={{ alignItems: 'center'}}>
                        <div id="google_translate_element"></div>
                        {/* <select
                           value={targetLanguage}
                           className='t-dropdown'
                        >
                           <option value="es" onClick={(e) => setTargetLanguage(e.target.value)}>English</option>
                           <option value="ar" onClick={(e) => setTargetLanguage(e.target.value)}>Arabic</option>
                        </select> */}
                     </div>
                  </div>
               </div>
            </div>
         </header>

         <Sidebar show={show} handleClose={handleClose} />
      </>
   );
};

export default NavbarMain;