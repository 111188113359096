import React from 'react';
// import { Link } from 'react-router-dom';

const FounderDetails = () => {
   return (
      <>
         <div className="doctor-details-area pt-150 pb-70">
            <div className="container">
               <div className="row">
               <div className="col-xl-3 col-lg-3">
                     <div className="service-widget mb-50">
                        <div className="team-wrapper team-box-2 team-box-3 text-center mb-30">
                           <div className="team-thumb">
                              <img src="/img/team/sawsan-youssef.jpg" alt="" />
                           </div>
                           <div className="team-member-info mt-35 mb-35">
                              <h3>Sawsan Youssef</h3>
                              <h6 className="f-500 text-up-case letter-spacing pink-color">Founder </h6>
                           </div>
                           <div className="team-social-profile">
                              <ul>
                                 <li><a href="https://www.facebook.com/public/Sawsan-Kassem-Youssef"><i className="fab fa-facebook-f"></i></a></li>
                                 <li><a href="https://www.linkedin.com/in/sawsan-kassem-youssef-584082210?originalSubdomain=uk"><i className="fab fa-linkedin"></i></a></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     {/* <div className="service-widget mb-50">
                        <div className="widget-title-box mb-30">
                           <h3 className="widget-title">Qualifications</h3>
                        </div>
                        <div className="more-service-list">
                           <ul>
                              <li>
                                 <Link to="/doctorDetails">
                                    <div className="more-service-icon"><img src="/img/icons/vision.svg" alt="" /></div>
                                    <div className="more-service-title doctor-details-title">PhD in Immunology<span>Technion-Israel Institute of Technology</span></div>
                                 </Link>
                              </li>
                              <li>
                                 <Link to="/doctorDetails">
                                    <div className="more-service-icon"><img src="/img/icons/vision.svg" alt="" /></div>
                                    <div className="more-service-title doctor-details-title">Postdoctoral Training<span>Stanford University</span></div>
                                 </Link>
                              </li>
                           </ul>
                        </div>
                     </div> */}
                  </div>
                  <div className="col-xl-9 col-lg-9" style={{display: 'flex', alignItems: 'center'}}>
                     <article className="doctor-details-box">
                        <div className="section-title pos-rel mb-25">
                           {/* <div className="section-icon">
                              <img className="section-back-icon back-icon-left" src="img/team/sawsan-youssef.png" alt="" />
                           </div> */}
                           <div className="section-text pos-rel">
                              <h5 className="green-color text-up-case">Founder</h5>
                              <h1>Sawsan Youssef</h1>
                           </div>
                           <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt="" />
                           </div>
                        </div>
                        <div className="service-details-text mb-40">
                           <p>I am a dedicated professional with a background in tourism, specializing in facilitating medical and health services for travelers seeking care abroad. My expertise lies in efficiently managing visa applications and travel arrangements for individuals embarking on medical journeys. I bring a wealth of experience in creating seamless and memorable experiences, ensuring smooth case progression while adhering to immigration policies and regulations. Fluent in Arabic, English, and French, I am committed to providing top-quality service to enhance your medical tourism experience and help you achieve better health and wellness.</p>
                           {/* <p>Sawsan then did her postdoctoral training at Stanford University in the Steinman Laboratory, where she focused on multiple sclerosis, exploring the inhibitory effect of statins on T cell differentiation and the reversal of neuro-inflammation in mice. This led to an investigator initiative phase I trial STAyCIS that exhibited positive data. Sawsan also worked on the effects of other metabolic pathways controlling the expansion of pro-inflammatory T cell subsets such Th1 and Th17 in MS. </p> */}

                           {/* <h3>2008 - 2013</h3>
                           <p>In 2008 Sawsan joined Rinat/Pfizer Inc. where she helped established the immunology department and initiated several antibody therapeutic programs focusing on immuno-oncology and autoimmune inflammation (lupus, psoriasis, IBD and MS). In 2013 she became the research project lead of Anti PD-1 (PF-06801591) now in Phase I.</p>

                           <h3>2015 - 2017</h3>
                           <p>Sawsan moved this project from initiation, through molecule discovery and into phase I trial in 2 years and was promoted to senior principal scientist in 2015 as she worked across multidisciplinary teams at Pfizer to characterize the clinical candidate and support pre-clinical IND activities. Her pre-clinical work was presented at AACR 2017 and Phase I data was presented at ESMO 2017.</p> 

                           <h3>2017 - 2019</h3>
                           <p>Sawsan joined Distributed Bio as Director of Immunology and Immuno-oncology, where she oversaw immune oncology, envenomation, vaccination, and cellular therapy engineering efforts in 2017. She was promoted to Chief Scientific Officer of Distributed Bio in 2019. She has joined</p> */}
                        </div>
                              
                     </article>
                  </div>
                  
               </div>
            </div>
         </div>
      </>
   );
};

export default FounderDetails;