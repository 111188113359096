import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import MedicalDetails from './medical-details';
import PageWrapper from '../../../components/Shared/PageWrapper';

const MedConsultation = () => {
    return (
        <PageWrapper page="main">
            <CommonPageHeader title="Medical Consultation & Planning" desc="Embark on a Journey Tailored Just for You" subtitle="Services" banner="medical-page_header_bg"/>
            <MedicalDetails/>
        </PageWrapper>
    );
};

export default MedConsultation;
