import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import TravelDetails from './travel-details';
import PageWrapper from '../../../components/Shared/PageWrapper';

const TravelAssistance = () => {
    return (
        <PageWrapper page="main">
            <CommonPageHeader title="Visa & Travel Assistance" desc="Seamless Travels, Stress-free Processes" subtitle="Services" banner="visa-travel_header_bg"/>
            <TravelDetails/>
        </PageWrapper>
    );
};

export default TravelAssistance;