import React from 'react';
import HomeTwoSingleService from '../../../components/HomeTwoSingleService/HomeTwoSingleService';

const HomeTwoServicesArea = () => {
   return (
      <>
         <section className="servcies-area greendark-bg pt-100 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-6 offset-xl-3 col-lg-10 offset-lg-1">
                     <div className="section-title text-center pos-rel mb-75">
                        <div className="section-icon">
                           {/* <img className="section-back-icon" src="img/section/section-back-icon.png" alt=""/> */}
                        </div>
                        <div className="section-text pos-rel">
                           <h1 style={{color: '#BACCDB'}}>Your Medical Wellness Journey begins </h1>
                           <h5 style={{color: '#5ED6C9'}}>With Us</h5>
                        </div>
                        {/* <div className="section-line pos-rel">
                           <img src="img/shape/section-title-line.png" alt=""/>
                        </div> */}
                     </div>
                  </div>
               </div>

               <div className="row" style={{justifyContent: 'center'}}>

                  <HomeTwoSingleService icon="handshake" number="1" title="Connect With Us" description="Your journey to healing starts with a simple message. Reach out anytime via email, WhatsApp, or phone – we're here whenever you need us." />
                  <HomeTwoSingleService icon="check-list" number="2" title="Complementary Evaluation" description="Engage with our International Team to share your concerns and questions. Let us understand your needs deeply. Together, we explore potential destinations and tailor a plan that speaks to your unique health and wellness journey." />
                  <HomeTwoSingleService icon="protection" number="3" title="Medical Matchmaking" description="We believe in facilitating quality care within your budget. Our dedicated team will negotiate with the right doctors, hospitals and service providers to fetch you world-class treatment." />
                  <HomeTwoSingleService icon="passport" number="4" title="Visa Assistance" description="Once you approve your tailored treatment plan, MyHealingJourney steps in. We guide you through the medical visa application, handling all the details. Count on us to secure your invitation letter from the treating hospital, simplifying the process." />
                  <HomeTwoSingleService icon="introduction" number="5" title="A Warm Welcome" description="Upon arrival, we will help to arrange your transportation to your hotel and hospital. Your comfort and well-being is our priority." />
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoServicesArea;