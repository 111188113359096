import React from 'react';
import PageWrapper from '../../../components/Shared/PageWrapper';


const TermsAndConditions = () => {
    return (
        <PageWrapper page="soon">
            <section className="about-area about-area-mid pt-150 pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-12 col-md-11">
                            <div className="about-right-side pt-25 mb-30">
                                <div className="about-title mb-20">
                                    <h5 className="pink-color">Policies</h5>
                                    <h1>Terms and Conditions</h1>
                                </div>
                                <div className="about-text">
                                    {/* 
                                        <h3 className='pink-color'></h3>
                                        <p></p>
                                    */}

                                    <h4 className='pink-color'>Introduction:</h4>
                                    <p>Welcome to MyHealingJourney. These terms and conditions outline the rules and regulations for the use of our website. By accessing this website, we assume you accept these terms and conditions. Do not continue to use MyHealingJourney if you do not agree to take all of the terms and conditions stated on this page.</p><br/>

                                    <h4 className='pink-color'>License to Use:</h4>
                                    <p>Unless otherwise stated, MyHealingJourney and/or its licensors own the intellectual property rights for all material on MyHealingJourney. You may view and/or print pages from&nbsp;
                                    <b><a href="https://www.myhealingjourney.uk" className="" style={{color: '#4bafa3', fontWeight: '300'}}>https://www.myhealingjourney.uk</a></b> for your personal use subject to restrictions set in these terms and conditions.</p><br/>

                                    <h4 className='pink-color'>Credits:</h4>
                                    <p><b><a href="https://www.freepik.com/" className="" style={{color: '#4bafa3', fontWeight: '300'}}>Freepik</a></b> serves as a source for some of the high-resolution images displayed on our website.</p><br/>

                                    <h4 className='pink-color'>Disclaimer:</h4>
                                    <p>We provide the information on this website for general informational purposes only. It is not intended as medical or professional advice. You should not use the information on this website as a substitute for professional healthcare advice or treatment. If you have any concerns about your health, you should consult with a qualified healthcare professional.</p><br/>
                                </div>
                                <div className="button-area">
                                    <a href="/about_us" className="primary_btn btn-icon ml-0"><span>+</span>Read About Us</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </PageWrapper>
    );
};

export default TermsAndConditions;