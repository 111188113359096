import React from 'react';
import CommonPageHeader from '../../components/CommonPageHeader/CommonPageHeader';
import ContactArea from './ContactArea/ContactArea';
import PageWrapper from '../../components/Shared/PageWrapper';
// import ContactMap from './ContactMap/ContactMap';


const ContactUs = () => {
    return (
        <PageWrapper page="main">
            <CommonPageHeader title="Contact MyHealingJourney" desc="" subtitle="Contact Us" banner="contact-page_header_bg" />
            <ContactArea/>
        </PageWrapper>
    );
};

export default ContactUs;