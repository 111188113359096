import React from 'react';
import { Link } from 'react-router-dom';

const HClinicDetails = () => {
    return (
        <>
            <section className="about-area pt-120 pb-120">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="section-title section-title-m-0 mb-30 pos-rel text-end">
                                <div className="section-icon">
                                    <img className="section-back-icon back-icon-right" src="img/section/section-back-icon.png" alt=""/>
                                </div>
                                <div className="section-text section-text-small pos-rel">
                                    <h5>Hospital & Clinic Coordination</h5>
                                    <h1>Access to renowned healthcare facilities</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="facalty-text mb-30">
                                {/* <p>Why settle for good when you can have the best? MyHealingJourney grants you direct access to the UK's most renowned healthcare facilities. We ensure smooth coordination with top-tier hospitals and clinics, ensuring that you're greeted with excellence every step of your journey.</p> */}
                                <p>At MyHealingJourney, we facilitate access not only to the UK's top healthcare institutions but also to premier hospitals worldwide. Our extensive network ensures that wherever you choose to undergo treatment, you'll be guaranteed excellence, efficiency, and empathy.</p>
                            </div>
                            <div className="col-xl-6 col-lg-5">
                                <div className="section-button text-end d-block d-lg-block">
                                    <Link to="/contact_us" className="primary_btn btn-icon ml-0"><span>+</span>Contact Our Team</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="service-details-area pt-0 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8">
                            <article className="service-details-box">
                                <div className="service-details-text mb-30">
                                    <p>Gain privileged access to the pinnacle of healthcare excellence within the UK through MyHealingJourney. We have established an unwavering commitment to connecting you with renowned healthcare facilities that stand as beacons of medical innovation and expertise. Our network spans across the most esteemed hospitals and clinics, ensuring that every step of your medical journey is marked by uncompromising quality and care.</p>
                                    {/* <p>At MyHealingJourney, we understand the significance of entrusting your well-being to the best hands. Our meticulous coordination grants you seamless entry to world-class healthcare establishments, where cutting-edge technology meets compassionate medical professionals. Whether it's diagnostic precision, advanced treatments, or comprehensive post-operative care, rest assured that our access to these esteemed facilities paves the way for your health to thrive.</p> */}
                                </div>
                                {/* <div className="service-details-feature fix mb-35">
                                    <div className="ser-fea-box f-left">
                                        <div className="ser-fea-icon f-left">
                                        <img src="img/services/ser-fea-icon-1.png" alt="" />
                                        </div>
                                        <div className="ser-fea-list fix">
                                        <h3>Personal care</h3>
                                        <ul>
                                            <li><i className="fas fa-check"></i>Cillum dolore eu fugiat nulla.</li>
                                            <li><i className="fas fa-check"></i>Lorem ipsum dolor sit amet.</li>
                                            <li><i className="fas fa-check"></i>Consectetur adipisicing elit,</li>
                                            <li><i className="fas fa-check"></i>Sed do eiusmod tempor inci.</li>
                                        </ul>
                                        </div>
                                    </div>
                                    <div className="ser-fea-box f-left me-0">
                                        <div className="ser-fea-icon f-left">
                                        <img src="img/services/ser-fea-icon-2.png" alt="" />
                                        </div>
                                        <div className="ser-fea-list fix">
                                        <h3>Lifestyle support</h3>
                                        <ul>
                                            <li><i className="fas fa-check"></i>Didunt ut labore et dolore magna.</li>
                                            <li><i className="fas fa-check"></i>Aliqua. Ut enim ad minim veniam.</li>
                                            <li><i className="fas fa-check"></i>Quis nostrud exercitation ullamco.</li>
                                            <li><i className="fas fa-check"></i>Laboris nisi ut aliquip ex ea.</li>
                                        </ul>
                                        </div>
                                    </div>
                                </div> */}
                            </article>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                            <div className="service-widget mb-50">
                                <div className="widget-title-box mb-30">
                                    <h3 className="widget-title">Other Services</h3>
                                </div>
                                <div className="more-service-list">
                                    <ul>
                                        <li>
                                        <Link to="/services/visa_and_travel_assistance">
                                            <div className="more-service-icon"><img src="/img/icons/travel.svg" alt="" /></div>
                                            <div className="more-service-title">Visa & Travel Assistance</div>
                                        </Link>
                                        </li>
                                        <li>
                                        <Link to="/services/hospital_and_clinic_coordination">
                                            <div className="more-service-icon"><img src="/img/icons/clinic.svg" alt="" /></div>
                                            <div className="more-service-title">Hospital & Clinic Coordination</div>
                                        </Link>
                                        </li>
                                        
                                        <li>
                                        <Link to="/services/accommodation_and_transportation">
                                            <div className="more-service-icon"><img src="/img/icons/accomodation.svg" alt="" /></div>
                                            <div className="more-service-title">Accommodation & Transportation</div>
                                        </Link>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default HClinicDetails;