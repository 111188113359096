import React from 'react';
import { Link } from 'react-router-dom';

const PatientDetails = () => {
    return (
        <>
            <section className="about-area pt-120 pb-120">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="section-title section-title-m-0 mb-30 pos-rel text-end">
                                <div className="section-icon">
                                    <img className="section-back-icon back-icon-right" src="img/section/section-back-icon.png" alt=""/>
                                </div>
                                <div className="section-text section-text-small pos-rel">
                                    <h5>Patient Consent Form</h5>
                                    <h1>Simple, easy to understand contract to establish absolute transparency.</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="facalty-text mb-30">
                                <p>At MyHealingJourney, transparency and clarity are fundamental to our approach. When you embark on a medical journey with us, we ensure that every detail is documented in a comprehensive Patient Contract/Agreement. This document encapsulates our mutual decisions, including the specific medical treatment, the hospital or clinic’s name, the cost breakdown, the designated hotel, and the exact duration of your treatment and stay. By formalizing these details in writing, we emphasize our commitment to keeping you well-informed and confident about your healthcare experience.</p>
                            </div>
                            <div className="col-xl-6 col-lg-5">
                                <div className="section-button text-end d-block d-lg-block">
                                    <Link to="/contact_us" className="primary_btn btn-icon ml-0"><span>+</span>Contact Our Team</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="service-details-area pt-0 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8">
                            <article className="service-details-box">
                                <div className="service-details-text mb-30">
                                    <p>Our dedication to transparency extends beyond mere documentation. It’s a promise we uphold diligently. We understand the significance of your trust, and that’s why we adhere rigorously to the agreements outlined in the Patient Contract/Agreement. This commitment translates into a seamless and hassle-free medical tourism experience for you. With us, you can rest assured that the journey you agreed upon is the journey you will experience – an experience marked by transparency, trust, and unwavering commitment to your well-being.</p>
                                    <p>MyHealingJourney’s Patient Contract/Agreement is not just a formality; it’s a testament to our unwavering dedication. We believe in fulfilling our promises and exceeding your expectations. Through transparent communication, meticulous planning, and a steadfast commitment to our agreements, we ensure that your medical journey is not just satisfactory, but exceptional. Your trust is our driving force, and we go above and beyond to honour it, ensuring that your experience with us is nothing short of outstanding.</p>
                                </div>
                            </article>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                        <div className="service-widget mb-50">
                            <div className="widget-title-box mb-30">
                                <h3 className="widget-title">Other Services</h3>
                            </div>
                            <div className="more-service-list">
                                <ul>
                                    <li>
                                    <Link to="/services/visa_and_travel_assistance">
                                        <div className="more-service-icon"><img src="/img/icons/travel.svg" alt="" /></div>
                                        <div className="more-service-title">Visa & Travel Assistance</div>
                                    </Link>
                                    </li>
                                    <li>
                                    <Link to="/services/hospital_and_clinic_coordination">
                                        <div className="more-service-icon"><img src="/img/icons/clinic.svg" alt="" /></div>
                                        <div className="more-service-title">Hospital & Clinic Coordination</div>
                                    </Link>
                                    </li>
                                    
                                    <li>
                                    <Link to="/services/accommodation_and_transportation">
                                        <div className="more-service-icon"><img src="/img/icons/accomodation.svg" alt="" /></div>
                                        <div className="more-service-title">Accommodation & Transportation</div>
                                    </Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default PatientDetails;