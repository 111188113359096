
import React from 'react';
import Footer from '../../components/Shared/Footer';
import NavBar from '../Navbar/navbar';
import NavbarNull from '../Navbar/navbar-null';
import ScrollToTopButton from "../ScrollToTopButton"

const PageWrapper = ({ page, children} ) => {
    return (
        <>
            {page === 'main' ? <NavBar/> : <NavbarNull/>}
            {children}
            <ScrollToTopButton />
            <Footer/>
        </>
    );
};

export default PageWrapper;