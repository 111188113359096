import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import PageWrapper from '../../../components/Shared/PageWrapper';
import HClinicDetails from './hclinic-details';


const HClinicCoordinate = () => {
    return (
        <PageWrapper page="main">
            <CommonPageHeader title="Hospital & Clinic Coordination" desc="World-Class Care Awaits You" subtitle="Services" banner="clinic-page_header_bg"/>
            <HClinicDetails/>
        </PageWrapper>
    );
};

export default HClinicCoordinate;
