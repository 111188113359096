import React from 'react';
import { Link } from 'react-router-dom';
import { Tilt } from 'react-tilt'


const HomeTwoSingleService = ({icon,number,title,description}) => {
    return (
        <>
          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <Tilt
               options={{
                  max: 45,
                  scale: 1,
                  speed: 450,
               }}
            >
               <div className="service-box-2 mb-30 pos-rel">
                  <div className="service-thumb">
                     <img src={`/img/icons/steps/${icon}.svg`} alt="" className='w-64'/>
                     {/* <i aria-hidden="true" class={icon}></i> */}
                  </div>
                  <div className="service-content service-content-2">
                     {/* <h6 className="green-color text-up-case letter-spacing mb-20">Older & Children</h6> */}
                     <h3><Link to="#">{title}</Link></h3>
                     <p>{description}</p>
                     {/* <Link className="service-link" to="/servicesDetails"><i className="fas fa-arrow-right"></i>Read More</Link> */}
                  </div>
                  <div className="service-number">
                     <h1 className="service-big-number">{`0${number}`}</h1>
                  </div>
               </div>
            </Tilt>
             
          </div>
        </>
    );
};

export default HomeTwoSingleService;