import React from 'react';
import HomeHeroSection from '../Home/HomeHeroSection/HomeHeroSection'
import PageWrapper from '../../components/Shared/PageWrapper'
import HomeServices from '../Home/HomeServices/HomeServices'
import HomeTwoServicesArea from '../Home/HomeTwoServicesArea/index'

const HomeMain = () => {
    return (
        <PageWrapper page="main">
            <HomeHeroSection/> 
            <HomeServices /> 
            <HomeTwoServicesArea /> 
        </PageWrapper>
    );
};

export default HomeMain;