import React from 'react';
import { Link } from 'react-router-dom';

const MedicalDetails = () => {
    return (
        <>
            <section className="about-area pt-120 pb-120">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="section-title section-title-m-0 mb-30 pos-rel text-end">
                                <div className="section-icon">
                                    <img className="section-back-icon back-icon-right" src="img/section/section-back-icon.png" alt=""/>
                                </div>
                                <div className="section-text section-text-small pos-rel">
                                    <h5>Medical Consultation & Planning</h5>
                                    <h1>Tailored treatment plans with top medical experts.</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="facalty-text mb-30">
                                <p>At MyHealingJourney, we understand that every individual is unique. Thus, our team of medical maestros meticulously crafts treatment plans keeping your specific needs in mind. Collaborate with the UK's top medical experts and ensure that your health journey is based on precision, expertise, and understanding. Choose MyHealingJourney, choose unparalleled personalised care.</p>
                            </div>
                            <div className="col-xl-6 col-lg-5">
                                <div className="section-button text-end d-block d-lg-block">
                                    <Link to="/contact_us" className="primary_btn btn-icon ml-0"><span>+</span>Contact Our Team</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="service-details-area pt-0 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8">
                            <article className="service-details-box">
                                <div className="service-details-text mb-30">
                                    <p>Experience healthcare at its finest with MyHealingJourney's tailored treatment plans, guided by the expertise of top-tier medical professionals. We recognise that each individual's health needs are distinct, which is why we craft personalised pathways to recovery, backed by the insights of renowned doctors. Our commitment to precision ensures that your journey to wellness is unique, optimized, and led by experts who are pioneers in their fields.</p>
                                    {/* <p>From diagnostics to <a href="/services/post-treatment_care_coordination">post-treatment care</a>, our approach revolves around you. Our collaborative consultations with leading medical specialists result in comprehensive and cohesive treatment strategies. At MyHealingJourney, we're not just shaping care; we're customising health solutions that align perfectly with your well-being goals</p> */}
                                    <p>Whether you're seeking the best of British healthcare or looking to explore exemplary treatments outside the UK, MyHealingJourney is your trusted partner. We ensure personalized care through bespoke treatment plans, guiding both inbound and outbound patients in their pursuit of optimal health. With MyHealingJourney, global wellness is within your reach.</p>
                                </div>
                            </article>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                        <div className="service-widget mb-50">
                            <div className="widget-title-box mb-30">
                                <h3 className="widget-title">Other Services</h3>
                            </div>
                            <div className="more-service-list">
                                <ul>
                                    <li>
                                    <Link to="/services/visa_and_travel_assistance">
                                        <div className="more-service-icon"><img src="/img/icons/travel.svg" alt="" /></div>
                                        <div className="more-service-title">Visa & Travel Assistance</div>
                                    </Link>
                                    </li>
                                    <li>
                                    <Link to="/services/hospital_and_clinic_coordination">
                                        <div className="more-service-icon"><img src="/img/icons/clinic.svg" alt="" /></div>
                                        <div className="more-service-title">Hospital & Clinic Coordination</div>
                                    </Link>
                                    </li>
                                    
                                    <li>
                                    <Link to="/services/accommodation_and_transportation">
                                        <div className="more-service-icon"><img src="/img/icons/accomodation.svg" alt="" /></div>
                                        <div className="more-service-title">Accommodation & Transportation</div>
                                    </Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MedicalDetails;