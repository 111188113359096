import React from 'react';
import PageWrapper from '../../../components/Shared/PageWrapper';


const CookiePolicy = () => {
    return (
        <PageWrapper page="soon">
            <section className="about-area about-area-mid pt-150 pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-12 col-md-11">
                            <div className="about-right-side pt-25 mb-30">
                                <div className="about-title mb-20">
                                    <h5 className="pink-color">Policies</h5>
                                    <h1>Cookie Policy</h1>
                                </div>
                                <div className="about-text">
                                    {/* 
                                        <h3 className='pink-color'></h3>
                                        <p></p>
                                    */}

                                    <h4 className='pink-color'>What Are Cookies:</h4>
                                    <p>Cookies are small text files that are stored on your computer or mobile device when you visit a website. They are widely used to make websites work, or work more efficiently, as well as to provide information to website owners.</p><br/>

                                    <h4 className='pink-color'>How We Use Cookies:</h4>
                                    <p>MyHealingJourney uses cookies for a variety of purposes. These may include tracking user behavior, improving user experience, and for targeted advertising. By using our website, you consent to the use of cookies in accordance with our Cookie Policy.</p><br/>
                                </div>
                                <div className="button-area">
                                    <a href="/about_us" className="primary_btn btn-icon ml-0"><span>+</span>Read About Us</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </PageWrapper>
    );
};

export default CookiePolicy;