import React from 'react';
import PageWrapper from '../../../components/Shared/PageWrapper';

const Disclaimer = () => {
    return (
        <PageWrapper page="soon">
            <section className="about-area about-area-mid pt-150 pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-12 col-md-11">
                            <div className="about-right-side pt-25 mb-30">
                                <div className="about-title mb-20">
                                    <h5 className="pink-color">Policies</h5>
                                    <h1>Disclaimer</h1>
                                </div>
                                <div className="about-text">
                                    {/* 
                                        <h3 className='pink-color'></h3>
                                        <p></p>
                                    */}

                                    <h4 className='pink-color'>No Medical Advice:</h4>
                                    <p>The information provided on MyHealingJourney is for general informational purposes only. We do not provide medical advice, diagnosis, or treatment. The information on this website should not be considered as a substitute for professional medical advice. Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition.</p><br/>

                                    <h4 className='pink-color'>No Warranties:</h4>
                                    <p>While we strive to provide accurate and up-to-date information, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information provided on MyHealingJourney. Any reliance you place on such information is strictly at your own risk.</p><br/>
                                </div>
                                <div className="button-area">
                                    <a href="/about_us" className="primary_btn btn-icon ml-0"><span>+</span>Read About Us</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </PageWrapper>
    );
};

export default Disclaimer;