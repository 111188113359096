import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import PatientDetails from './patient-details';
import PageWrapper from '../../../components/Shared/PageWrapper';

const PatientConsentForm = () => {
    return (
        <PageWrapper page="main">
            <CommonPageHeader title="Patient Consent Form" subtitle="Services" desc="Your Comfort, Our Priority" banner="personal-page_header_bg"/>
            <PatientDetails />
        </PageWrapper>
    );
};

export default PatientConsentForm;