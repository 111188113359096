import React from 'react';
// import { Link } from 'react-router-dom';
import DoctorsTwoSingleTeam from '../../../../components/DoctorsTwoSingleTeam/DoctorsTwoSingleTeam';

const DoctorsTwoTeamArea = () => {
    return (
        <>
            <section className="team-area pt-120 pb-90 gray-bg">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                            <div className="section-title pos-rel mb-75">
                                {/* <div className="section-icon">
                                    <img className="section-back-icon back-icon-left" src="img/section/section-back-icon.png" alt=""/>
                                </div> */}
                                <div className="section-text pos-rel text-center">
                                    {/* <h5>Our Team</h5> */}
                                    <h1>Meet The Team</h1>
                                </div>
                                {/* <div className="section-line pos-rel">
                                    <img src="img/shape/section-title-line.png" alt=""/>
                                </div> */}
                            </div>
                        </div>
                        
                    </div>
                    <div className="row" style={{justifyContent: 'center'}}>

                        <DoctorsTwoSingleTeam 
                            image="founder.png" 
                            name="Sawsan Youssef" 
                            title="Founder" 
                            about="Sawsan’s passion is promoting health and wellness of individuals, communities and the planet.  She has over 15 years experience facilitating people’s ability to travel the world through her extensive experience in the fields of immigration, hospitality and wellness.  Sawsan is driven by her genuine passion in connecting and relating to people of all backgrounds and cultures.  She is fluent in Arabic, French, and English with working knowledge of Italian and Spanish. " 
                        />

                        <DoctorsTwoSingleTeam 
                            image="development-advisor.png" 
                            name="Jamie Schnurr" 
                            title="Research, Planning and Development Advisor" 
                            about="Jamie has 25 years experience in international development focused on health, education and social protection. As a former Canadian diplomat for 15 years, he has held senior positions in several countries around the world, including Head of Development and Humanitarian Cooperation and acting Ambassador in Africa and the Middle East." 
                        />

                        <DoctorsTwoSingleTeam 
                            image="business-advisor.png" 
                            name="Keerthi Herath" 
                            title="Business Development Advisor" 
                            about="Keerthi is Founder and CEO of Wellness Ceylon a global wellness travel service specialising in high quality affordable Ayurvedic treatments. Keerthi has over 20 years of experience in establishing successful businesses in a range of sectors, including health and wellness." 
                        />

                        <DoctorsTwoSingleTeam 
                            image="tourism-advisor.png" 
                            name="Dr Prem Jagyasi" 
                            title="Medical and Wellness Tourism Advisor" 
                            about="Dr. Prem Jagyasi is a globally recognized expert and award-winning speaker in Medical Tourism and Wellness Tourism. As the CEO of Dr Prem & Associates, he leads a team specialising in consultancy services for the Medical Tourism, Global Healthcare, and Wellness Tourism industries. With over 20 years of experience, Dr. Prem has provided strategic solutions to clients worldwide, helping them achieve success and profitability." 
                        />
                        
                        <DoctorsTwoSingleTeam 
                            image="strategic-advisor.png" 
                            name="Sean Southey" 
                            title="Strategic Communications Advisor" 
                            about="Sean has 25 years of experience in strategic communications driven by his deep passion for healing the planet and generating tangible results through guiding the strategic direction of key public and private organisations. " 
                        />


                        {/* <DoctorsTwoSingleTeam image="melody-t-campbell.jpg" name="Melody T.Campbell" facebook="" linkedIn="" twitter="" title="Therapist" link="#" />
                        <DoctorsTwoSingleTeam image="jeffery-osborne.jpg" name="Jeffery Osborne" facebook="" linkedIn="" twitter="" title="Insurance Consultant" link="#" /> */}
                        
                        {/* <DoctorsTwoSingleTeam image="puleng-mwanajuma" name="Puleng Mwanajuma" title="Post-Treatment Consultant" />
                        <DoctorsTwoSingleTeam image="thomas-gill" name="Thomas Gill" title="Visa Attorney" /> */}
                        
                    </div>
                
                </div>
            </section>
        </>
    );
};

export default DoctorsTwoTeamArea;