import React from 'react';
import { Link } from 'react-router-dom';


const ServicesTwoAbout = () => {
    return (
        <>
            <section className="about-area pt-120 pb-50">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="section-title section-title-m-0 mb-30 pos-rel text-end">
                                <div className="section-icon">
                                    <img className="section-back-icon back-icon-right" src="img/section/section-back-icon.png" alt=""/>
                                </div>
                                <div className="section-text section-text-small pos-rel">
                                    <h5>Our professional team</h5>
                                    <h1>We will guide you through every step of your medical journey</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="facalty-text mb-30">
                                <p>Behind MyHealingJourney's outstanding services is a team of dedicated professionals who are passionate about your well-being. Get acquainted with the faces that make global wellness journeys seamless, effective, and memorable. From medical experts to wellness enthusiasts, our team is a blend of experience, passion, and commitment.</p>
                            </div>
                            <div className="col-xl-6 col-lg-5">
                                <div className="section-button text-end d-none d-lg-block">
                                    <Link to="/contact_us" className="primary_btn btn-icon ml-0"><span>+</span>Contact Our Team</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServicesTwoAbout;