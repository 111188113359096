import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import PageWrapper from '../../../components/Shared/PageWrapper';
import AyurverdicDetails from './ayurverdic-details';

const AyurverdicTreatments = () => {
    return (
        <PageWrapper page="main">
            <CommonPageHeader title="Ayurvedic Treatments" subtitle="Wellness" desc="Beyond Conventional, Towards Personal" banner="ayurvedic_header_bg"/>
            <AyurverdicDetails />
        </PageWrapper>
    );
};

export default AyurverdicTreatments;