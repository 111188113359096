import React from 'react';
// import { motion } from 'framer-motion';
import { LazyMotion, domAnimation, m } from "framer-motion"
// import { fadeIn, textVariant } from '../../../utils/motion';
import HomeTwoSingleAbout from '../../../components/HomeTwoSingleAbout/HomeTwoSingleAbout';

const HomeServices = () => {
   return (
      <>
         <section className="servcies-area gray-bg pt-115 pb-90">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1">
                     <LazyMotion features={domAnimation}>
                        <m.div animate={{ opacity: 1 }} className="section-title text-center pos-rel mb-75">
                           
                           <div className="section-icon">
                              <img className="section-back-icon" src="img/section/section-back-icon.png" alt=""/>
                           </div>
                           <div className="section-text pos-rel home_ser_title">
                              <h5>My Healing Journey</h5>
                              <h1>Your Doorway to Exceptional Healthcare</h1>
                              <h4 className='mini-title'>
                                 At MyHealingJourney, we are committed to transforming lives through the gift of health and wellness.
                              </h4>
                           </div>
                           {/* <div className="section-line pos-rel">
                              <img src="img/shape/section-title-line.png" alt=""/>
                           </div> */}
                        </m.div>
                     </LazyMotion>
                  </div>
               </div>
               <div className="row align-items-center" style={{justifyContent: 'center'}}>
                    <HomeTwoSingleAbout index="1" icon="discovery" title="Discover" description="Explore a world of advanced healthcare solutions tailored to your needs." spacing="b-40" />
                    <HomeTwoSingleAbout index="2" icon="connection" title="Connect" description="Access top-tier medical services, from cutting-edge treatments to trusted professionals." spacing="t-40" />
                    <HomeTwoSingleAbout index="2" icon="support" title="Thrive" description="Prioritise your well-being with our seamless support, ensuring a stress-free journey to a healthier, happier you." spacing="b-30" />
               </div>
            </div>
         </section>
      </>
   );
};

export default HomeServices;