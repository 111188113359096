import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AllContext from './context/AllContext';
import MeetTheTeam from './pages/Doctors/MeetTheTeam/meet-the-team';
import HomeMain from './pages/HomeTwo/home-main';
import ScrollToTopEffect from './components/ScrollToTopEffect';
import './App.css';

import NotFound from './pages/NotFound/NotFound';
import ComingSoon from './pages/NotFound/coming-soon';
import ContactUs from './pages/Contact/Contact';
import Founder from './pages/Doctors/Founder/founder'

import MedConsultation from './pages/Services/medical-consultation/medical-consultation'
import HClinicCoordinate from './pages/Services/hospital-clinic-coordination/hclinic-coordination'
import Accomodation from './pages/Services/accommodation-transportation/accommodation'
import TravelAssistance from './pages/Services/travel-assistance/travel-assistance'
import PatientConsentForm from './pages/Services/patient-consent-form/index'
import AyurverdicTreatments from './pages/Services/ayurverdic-treatments/index'

import WellnessCeylon from './pages/Partnership/wellness-ceylon/index'

import MTCardiology from './pages/Medical/cardiology/index'
import MTCosmeticSurgery from './pages/Medical/cosmetic_surgery/index'
import MTDental from './pages/Medical/dental/index'
import MTFertility from './pages/Medical/fertility/index'
import MTNeurology from './pages/Medical/neurology/index'
import MTOncology from './pages/Medical/oncology/index'
import MTOphthalmology from './pages/Medical/ophthalmology/index'
import MTOrthopaedics from './pages/Medical/orthopaedics/index'


import TermsAndConditions from './pages/Policies/terms-and-conditions/index'
import Disclaimer from './pages/Policies/disclaimer/index'
import PrivacyPolicy from './pages/Policies/privacy-policy/index'
import CookiePolicy from './pages/Policies/cookie-policy/index'
import DoNotSellMyInfo from './pages/Policies/do-not-sell-my-info/index'
import ManageCookiePreferences from './pages/Policies/manage-cookie-preferences/index'


// import AlternativeTherapies from './pages/Services/alternative-therapies-coordination/alternative-therapies'
// import PostTreatmentCare from './pages/Services/post-treatment-care/post-treatment-care'
// import PersonalAssistance from './pages/Services/personal-assistance/personal-assistance'

import {QueryClient, QueryClientProvider} from 'react-query';


const queryClient = new QueryClient();

function App() {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: "en,ar",
        autoDisplay: false,
        
      },
      "google_translate_element"
    );
  };


  // translate to arabic
  useEffect(() => {

    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
    // google_translate_mobile_element
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AllContext>
        <BrowserRouter>
          <ScrollToTopEffect />
          {/* <ScrollRestoration onBeforeRestore={() => window.scrollTo(0, 0)}> */}
          <Routes>
            <Route path="/" element={<HomeMain />} />
            <Route path="/about_us" element={<MeetTheTeam />} />

            <Route path="/sawsan_youssef" element={<Founder />} />

            <Route path="/contact_us" element={<ContactUs />} />
            <Route path="/services/medical_consultation" element={<MedConsultation />} />
            <Route path="/services/hospital_and_clinic_coordination" element={<HClinicCoordinate />} />
            <Route path="/services/accommodation_and_transportation" element={<Accomodation />} />
            <Route path="/services/visa_and_travel_assistance" element={<TravelAssistance />} />
            <Route path="/services/patient_consent_form" element={<PatientConsentForm />} />

            <Route path="/partnership/wellness_ceylon" element={<WellnessCeylon />} />
            <Route path="/wellness/ayurverdic_treatments" element={<AyurverdicTreatments />} />

            <Route path="/medical_treatments/cardiology" element={<MTCardiology />} />
            <Route path="/medical_treatments/cosmetic_surgery" element={<MTCosmeticSurgery />} />
            <Route path="/medical_treatments/dental" element={<MTDental />} />
            <Route path="/medical_treatments/fertility" element={<MTFertility />} />
            <Route path="/medical_treatments/neurology" element={<MTNeurology />} />
            <Route path="/medical_treatments/oncology" element={<MTOncology />} />
            <Route path="/medical_treatments/ophthalmology" element={<MTOphthalmology />} />
            <Route path="/medical_treatments/orthopaedics" element={<MTOrthopaedics />} />

            <Route path="*" element={<NotFound />} />
            <Route path="/coming_soon" element={<ComingSoon />} />

            <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/privacy_policy" element={<PrivacyPolicy />} />
            <Route path="/cookie_policy" element={<CookiePolicy />} />
            <Route path="/do_not_sell_my_info" element={<DoNotSellMyInfo />} />
            <Route path="/manage_cookie_preferences" element={<ManageCookiePreferences />} />
          </Routes>
          {/* </ScrollRestoration> */}
          
        </BrowserRouter>
      </AllContext>
    </QueryClientProvider>
  );
}

export default App;


