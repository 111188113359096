import React from 'react';
import { Link } from 'react-router-dom';

const AccommodationDetails = () => {
    return (
        <>
            <section className="about-area pt-120 pb-120">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="section-title section-title-m-0 mb-30 pos-rel text-end">
                                <div className="section-icon">
                                    <img className="section-back-icon back-icon-right" src="img/section/section-back-icon.png" alt=""/>
                                </div>
                                <div className="section-text section-text-small pos-rel">
                                    <h5>Accommodation & Transportation</h5>
                                    <h1>Hassle-free travel and stay arrangements</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="facalty-text mb-30">
                                <p>With MyHealingJourney, your medical journey becomes an experience of ease and luxury. From the moment you land in the UK, every travel detail is meticulously planned. Stay at premium accommodations and travel comfortably with our comprehensive transportation services. All you need to focus on is your well-being; we handle the rest.</p>
                            </div>
                            <div className="col-xl-6 col-lg-5">
                                <div className="section-button text-end d-block d-lg-block">
                                    <Link to="/contact_us" className="primary_btn btn-icon ml-0"><span>+</span>Contact Our Team</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="service-details-area pt-0 pb-100">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8">
                        <article className="service-details-box">
                            <div className="service-details-text mb-30">
                                {/* <p>Elevate your healthcare journey with MyHealingJourney's unparalleled accommodation and transportation services meticulously arranged for our valued clients within the UK. We recognise that comfort and convenience are essential components of your overall well-being, which is why we take the utmost care in curating a seamless experience for your stay and travels. From luxury accommodations that embody comfort and tranquillity to efficient and hassle-free transportation solutions, our commitment is to ensure that every aspect of your journey contributes to your peace of mind.</p> */}
                                <p>Whether you're arriving from afar or transitioning between medical appointments, MyHealingJourney takes the stress out of logistics. Our dedicated team handles all the details, from selecting accommodations that cater to your preferences and needs, to arranging smooth and timely transportation so you can focus solely on your health and recovery. At MyHealingJourney, we pride ourselves on offering not just medical solutions, but holistic experiences that transform healthcare journeys into moments of comfort and ease.</p>
                            </div>
                        
                        </article>
                        </div>
                        <div className="col-xl-4 col-lg-4">
                        <div className="service-widget mb-50">
                            <div className="widget-title-box mb-30">
                                <h3 className="widget-title">Other Services</h3>
                            </div>
                            <div className="more-service-list">
                                <ul>
                                    <li>
                                    <Link to="/services/visa_and_travel_assistance">
                                        <div className="more-service-icon"><img src="/img/icons/travel.svg" alt="" /></div>
                                        <div className="more-service-title">Visa & Travel Assistance</div>
                                    </Link>
                                    </li>
                                    <li>
                                    <Link to="/services/hospital_and_clinic_coordination">
                                        <div className="more-service-icon"><img src="/img/icons/clinic.svg" alt="" /></div>
                                        <div className="more-service-title">Hospital & Clinic Coordination</div>
                                    </Link>
                                    </li>
                                    
                                    <li>
                                    <Link to="/services/accommodation_and_transportation">
                                        <div className="more-service-icon"><img src="/img/icons/accomodation.svg" alt="" /></div>
                                        <div className="more-service-title">Accommodation & Transportation</div>
                                    </Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default AccommodationDetails;