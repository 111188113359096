import React from 'react';
// import { Link } from 'react-router-dom';

const CommonPageHeader = ({title, subtitle, desc, banner}) => {
   return (
      <>
         <section className={`breadcrumb-bg pt-200 pb-180 ${banner}`}>
            <div className="container">
               <div className="row">
                  <div className="col-lg-9 col-md-9">
                     <div className="page-title">
                        <p className="small-text pb-15">{desc}</p>
                        <h1>{title}</h1>
                     </div>
                  </div>
                  <div className="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center">
                     {/* <div className="page-breadcumb">
                        <nav aria-label="breadcrumb">
                           <ol className="breadcrumb ">
                              <li className="breadcrumb-item">
                                 <Link to="/">Home</Link>
                              </li>
                              <li className="breadcrumb-item active" aria-current="page">{subtitle}</li>
                           </ol>
                        </nav>
                     </div> */}
                  </div>
               </div>
            </div>
         </section>
      </>
   );
};

export default CommonPageHeader;