import React from 'react';
import PageWrapper from '../../../components/Shared/PageWrapper';
import privacyPolicyData from '../../../data/privacy-policy.json'

const PrivacyPolicy = () => {
    const ppdata = privacyPolicyData
    
    return (
        <PageWrapper page="soon">
            <section className="about-area about-area-mid pt-150 pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-lg-12 col-md-11">
                            <div className="about-right-side pt-25 mb-30">
                                <div className="about-title mb-20">
                                    <h5 className="pink-color">{ppdata.tag}</h5>
                                    <h1>{ppdata.title}</h1>
                                </div>
                                <div className="about-text">
                                    {/* 
                                        <h3 className='pink-color'></h3>
                                        <p></p>
                                    */}

                                    <h4 className='pink-color'>{ppdata.policies.policy_1}</h4>
                                    <p>{ppdata.policies.policy_1_content}</p><br/>

                                    <h4 className='pink-color'>{ppdata.policies.policy_2}</h4>
                                    <p>{ppdata.policies.policy_2_content}</p><br/>
                                </div>
                                <div className="button-area">
                                    <a href="/about_us" className="primary_btn btn-icon ml-0"><span>+</span>{ppdata.cta}</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </PageWrapper>
    );
};

export default PrivacyPolicy;