import React from 'react';
import { Link } from 'react-router-dom';
// import HomeTwoSingleAbout from '../../../components/HomeTwoSingleAbout/HomeTwoSingleAbout';

const HomeTwoAbout = () => {
   return (
      <>
         <section className="about-area about-area-mid pt-120 pb-50">
            <div className="container">
               <div className="row">
                  <div className="col-xl-8 offset-xl-2 col-lg-12 col-md-11">
                     <div className="about-right-side pt-25 mb-30">
                        <div className="about-title mb-20">
                           <h5 className="pink-color">Partnership</h5>
                           <h1>Wellness Ceylon</h1>
                        </div>
                        <div className="about-text">
                           <p>MyHealingJourney has established an appealing partnership with Wellness Ceylon at a variety of esteemed Ayurvedic Wellness resorts nestled in the serene landscapes of Sri Lanka. Here, amidst the whispers of nature, we bring you a selection of attractive Ayurvedic treatments, especially priced for our valued MyHealingJourney patrons. Immerse yourself in a unique journey of health and wellness, guided by the ancient rhythms of Ayurvedic wisdom that have endured the sands of time.</p>
 
                           <p>At Wellness Ceylon, all treatments are customised and provided by Ayurvedic physicians that have a legacy of ayurvedic knowledge, otherwise known as Paaramparika Wedha in Sinhala.  This includes a wide range of authentic Ayurveda healthcare treatment packages for the prevention and promotion of health and various illness conditions.</p>
 
                           <p>Wellness Ceylon also offers you a world of relaxation and calm that will waft you far from the cares of everyday life while taking you through a series of Asian healing traditions with a holistic focus on spiritual, and mental health.</p>
 
                           <p>Use the following code Healing101 to receive a £50 cash back on any booking over £1000. For more information on packages and costs.</p><br/>
                           <p>
                              {/* <b><Link to="/contact_us" className="" style={{color: '#4bafa3', fontWeight: '500'}}>Contact Us</Link></b> */}
                           </p>
                        </div>
                        <div className="button-area">
                           <a href="https://wellnessceylon.co.uk/" target="_blank" rel="noreferrer" className="primary_btn btn-icon ml-0"><span>+</span>Visit Wellness Ceylon</a>
                        </div>
                     </div>
                  </div>

               </div>
            </div>
         </section>
      </>
   );
};

export default HomeTwoAbout;