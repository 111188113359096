import React from 'react';
import CommonPageHeader from '../../../components/CommonPageHeader/CommonPageHeader';
import ServicesTwoAbout from '../../Services/ServicesTwo/ServicesTwoAbout/ServicesTwoAbout'
import DoctorsTwoTeamArea from './DoctorsTwoTeamArea/DoctorsTwoTeamArea';
import PageWrapper from '../../../components/Shared/PageWrapper'


const MeetTheTeam = () => {
    return (
        <PageWrapper page="main">
            <CommonPageHeader title="About MyHealingJourney" desc="Your Journey To Wellnes Begins Here" subtitle="About Us" banner="about-page_header_bg"/>
            <ServicesTwoAbout/>
            <DoctorsTwoTeamArea/>
        </PageWrapper>
    );
};

export default MeetTheTeam;